<template>
  <div class="box is-paddingless" :class="{'tabbed': tabbed}">
    <div class="table-container mb-0">
      <table class="table is-striped is-hoverable is-fullwidth">
        <thead class="has-background-white-ter has-text-grey-dark has-text-weight-semibold">
          <tr>
            <td>Item</td>
            <td class="is-narrow has-text-right">Price Per Pax</td>
            <td class="is-narrow has-text-right">Quantity</td>
            <td class="is-narrow has-text-right">Total</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, itemIndex) in this.invoice.items"
            :key="itemIndex"
            class="is-size-7-half"
            >
              <td>
                <p class="is-uppercase has-text-weight-semibold">{{ item.description }}</p>
                <template v-if="item.items && item.items.length > 0">
                  <p
                    v-for="(subItem, index) in item.items"
                    :key="index"
                    class="is-size-7 has-text-grey"
                    >
                      <template v-if="!subItem.hide">
                        <span class="icon is-small">
                          <span class="fas fa-angle-right fa-xs"></span>
                        </span>
                        <span>{{ subItem.description }}</span>
                      </template>
                  </p>
                </template>
              </td>
              <td class="has-text-right">
                <p class="has-text-weight-semibold">{{ item.getPrice() | toCurrency }}</p>
                <template v-if="item.items && item.items.length > 0">
                  <div
                    v-for="(subItem, index) in item.items"
                    :key="index"
                    class="is-size-7 has-text-grey"
                    >
                      <template v-if="!subItem.hide">
                        {{ subItem.getPrice() | toCurrency }}
                      </template>
                  </div>
                </template>
              </td>
              <td class="has-text-right">
                <p class="has-text-weight-semibold">{{ item.quantity }}</p>
                <template v-if="item.items && item.items.length > 0">
                  <div
                    v-for="(subItem, index) in item.items"
                    :key="index"
                    class="is-size-7 has-text-grey"
                    >
                      <template v-if="!subItem.hide">
                        {{ subItem.quantity }}
                      </template>
                  </div>
                </template>
              </td>
              <td class="has-text-right">
                <p class="has-text-weight-semibold">{{ item.getPrice() * item.quantity | toCurrency }}</p>
                <template v-if="item.items && item.items.length > 0">
                  <div
                    v-for="(subItem, index) in item.items"
                    :key="index"
                    class="is-size-7 has-text-grey"
                    >
                      <template v-if="!subItem.hide">
                        {{ subItem.getPrice() * subItem.quantity | toCurrency }}
                      </template>
                  </div>
                </template>
              </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="columns is-mobile is-multiline is-marginless is-size-7-half" style="border-top:solid 1px hsl(0deg 0% 86%);">
      <div class="column is-12-mobile invoice-payments content mb-1">
        <template v-if="(!this.invoice.payments || this.invoice.payments.length == 0) && !this.invoice.datePaid">
          <p class="is-uppercase has-text-grey">Pending payment</p>
        </template>
        <template v-else-if="this.invoice.payments.length > 0">
          <p class="has-text-weight-semibold is-uppercase">Payment{{ this.invoice.payments.length > 0 ? 's' :'' }}:</p>
          <ul class="mt-0">
            <li
              v-for="(payment, paymentIndex) of this.invoice.payments"
              :key="paymentIndex"
              class="has-text-grey-dark mb-2"
              >
              <span class="has-text-weight-semibold">{{ payment.amount | toCurrency }}</span> - {{ payment.createdAt | formatDate('MMM. Do, YYYY') }}<br />
              <template v-if="payment.paymentType == 'card'">
                <span class="has-text-grey is-size-7">{{ payment.cardType }} ({{ payment.lastFour }})</span>
              </template>
              <template v-else-if="payment.paymentType === 'cash'">
                <span class="has-text-grey is-size-7">{{ payment.accountNumber }} ({{ payment.accountType }})</span>
              </template>
              <template v-else>
                  <span class="has-text-grey is-size-7">PayPal: {{ payment.payPalAddress }}</span>
              </template>
            </li>
          </ul>
        </template>
      </div>
      <div class="column is-12-mobile is-6-tablet is-4-desktop invoice-totals">
        <slot name="totals" :invoice="this.invoice"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    invoice: {
      type: Object,
      required: true,
      default: () => {},
    },
    tabbed: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/all";
.box {
  &.tabbed {
    border-top: none;
  }
  .invoice-totals {
    .columns {
      margin: -0.25rem;
    }
    .column {
      padding: 0.25rem;
    }
  }
  table td {
    white-space: nowrap;
  }
}
@include mobile() {
  .invoice-payments {
    order: 2;
  }
  .invoice-totals {
    border-bottom: solid 1px hsl(0, 0%, 86%);
    order: 1;
  }
}
</style>
